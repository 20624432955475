<template>
  <div :class="scroll == true ? 'onScroll' : 'UnOnScroll'">
    <div
      class="d-sm-block d-md-flex justify-content-between align-items-center"
      :class="underline"
    >
      <div class="d-flex flex-column align-items-start left-header">
        <nav aria-label="breadcrumb p-0 m-0" :class="breadcrumb">
          <ol class="breadcrumb m-0 pb-2 align-items-center">
            <li class="breadcrumb-item m-0 fnt-regular fnt-body">
              <router-link
                @click="OpenForm(false, 1)"
                to=""
                class="text-decoration-none text-muted"
                :class="device == 'mobile' ? 'fnt-caption' : ''"
                >{{
                  headerNamebreadcrumb == "" ? headerName : headerNamebreadcrumb
                }}
              </router-link>
            </li>
            <li class="breadcrumb-item m-0 fnt-regular fnt-body">
              <router-link
                v-if="blnOpenform && morebreadcrumbpath"
                @click="OpenForm(false, 2)"
                to=""
                class="text-decoration-none text-muted"
                :class="device == 'mobile' ? 'fnt-caption' : ''"
                >{{ breadcrumbname }}
              </router-link>
              <!-- {{ breadcrumbname === "" ? btnName : breadcrumbname }}
               -->
              {{ !blnOpenform || !morebreadcrumbpath ? breadcrumbname : "" }}
            </li>
            <li
              v-if="morebreadcrumbpath && blnOpenform"
              class="breadcrumb-item m-0 fnt-regular fnt-body"
              aria-current="page"
              :class="device == 'mobile' ? 'fnt-caption' : ''"
            >
              <!-- {{ breadcrumbname === "" ? btnName : breadcrumbname }}
               -->
              {{ morebreadcrumbname }}
            </li>
          </ol>
        </nav>
        <label
          for="campus"
          style="width: fit-content"
          class="d-flex align-items-center"
        >
          <!-- Header campus -->

          <!-- <Icon :icon="headerIcon" width="28"  class="text-primary" /> -->
          <component
            :is="icon"
            :width="widthIcon"
            :height="heightIcon"
            class="fill-primary"
            :style="
              device == 'mobile'
                ? 'width:24px !important;height:24px !important;'
                : ''
            "
          ></component>
          <h4
            class="ms-2 m-0 text-primary fnt-medium"
            :class="device == 'mobile' ? 'fnt-title' : ''"
          >
            <!-- {{ breadcrumbname === "" ? headerName : breadcrumbname }} -->
            {{ headerName }}{{ DetailheaderName }}
          </h4>

          <!-- Select campus -->
          <div class="select-campus opacity-0 d-none" :class="ListCampusClass">
            <select
              aria-label="Default select example"
              class="border form-select text-primary ms-5 dropdown-header"
              @change="selected($event.target.value)"
            >
              <option
                v-for="(op, index) in dataselection"
                :key="index"
                style="height: 48px !important"
                class="text-primary"
                :value="op['strID']"
              >
                {{ op["strCampusNameEN"] }}
              </option>
            </select>
          </div>
        </label>
        <p
          class="mt-1"
          :class="hintHelperHeader || device == 'mobile' ? 'fnt-caption' : ''"
        >
          {{ helperHeader }}
        </p>
      </div>
      <div
        :class="
          device == 'mobile'
            ? 'row g-0 d-flex align-items-center' + ' ' + rightHeader
            : 'd-flex align-items-center justify-content-end right-header' +
              ' ' +
              rightHeader
        "
      >
        <div
          v-if="blnShowSearchInput == true"
          :class="[
            tooltipSearchMsg
              ? device == 'mobile'
                ? 'tooltiper-mobile tooltip-top'
                : 'tooltiper tooltip-top'
              : '', // เพิ่มคลาส tooltip ถ้า tooltipSearchMsg เป็นจริง
            device === 'mobile' ? 'col-8' : 'me-lg-4 my-lg-0 my-sm-3 me-sm-1', // เลือกคลาสตาม device
          ]"
          :data-tooltip="tooltipSearchMsg ? $t(tooltipSearchMsg) : null"
        >
          <InputSearch
            :mode="mode"
            :hint="Searchname"
            :class="searchHeader"
            :classAutoSearch="classAutoSearch"
            @onSearch="search($event)"
            @onClean="clean()"
            ref="inputSearch"
          />
        </div>
        <!-- Search campus list -->
        <!-- <div class="d-flex justify-content-end"> -->
        <Button
          v-if="
            filterActive.useFilter == false &&
            filterActive.disableFilter == false
          "
          icon="BIconFilterOutline"
          classBtnIcon="fill-black"
          @click="OpenFilter"
          :class="filter + (device == 'mobile' ? 'col' : '')"
        />
        <button
          v-if="
            filterActive.useFilter == true &&
            filterActive.disableFilter == false
          "
          @click="OpenFilter"
          class="used btn"
        >
          <BIconFilterOutline class="fill-primary" width="24" height="24" />

          <div class="red-dot"></div>
        </button>
        <div
          class="disabled"
          :class="
            device == 'mobile'
              ? filterActive.disableFilter == false
                ? 'd-none'
                : 'col text-end ms-1'
              : ''
          "
        >
          <!-- <div class="box"></div> -->
          <Button
            v-if="filterActive.disableFilter == true"
            icon="BIconFilterOutline"
            classBtnIcon="fill-black-500"
            class="col-md-auto tooltip-config"
            widthIcon="24"
            heightIcon="24"
            dataToggle="tooltip"
            :tooltipMsg="$t(tooltipMsg)"
            :tooltipPlacement="tooltipPlacement"
            classBtn="tooltiper"
          />
        </div>
        <!-- Filter -->
        <div
          :class="
            device == 'mobile'
              ? 'col text-end' + ' ' + addHeader
              : ' ' + addHeader
          "
          class="ms-lg-3 ms-sm-1"
        >
          <router-link
            :class="
              disabled ? 'disabled_button text-white pe-none' : 'btn-primary '
            "
            @click="OpenForm(true)"
            to=""
            class="btn fnt-regular fnt-subtitle"
          >
            <Icon
              icon="fluent:add-24-filled"
              :class="device == 'mobile' || device == 'tablet' ? '' : 'me-2'"
            />
            <span
              :class="
                device == 'mobile'
                  ? 'd-none'
                  : '' && device == 'tablet'
                  ? 'd-flex'
                  : ''
              "
              >{{ btnName }}</span
            >
          </router-link>
        </div>
        <!-- </div> -->
      </div>
      <Heading v-if="heading" />
    </div>
  </div>
</template>

<script>
import Heading from "@/components/form/UserInfo/heading.vue";
import { Icon } from "@iconify/vue";
import {
  prefixOutline,
  campusOutline,
  agencyOutline,
  userfieldOutline,
  templateoutline,
  positionOutline,
  educationlevelOutline,
  usertypeOutline,
  parameterOutline,
  BIconFilterOutline,
  FilterActive,
  UserOutline,
} from "@/components/icon/index";
// import Button from "../button/button.vue";
import InputSearch from "../input/inputSearch.vue";
import { typeSchema } from "../constants/schemaAddCampus";
import Button from "../button/button.vue";
// import modal from "../../components/dialog/model.vue";
export default {
  components: {
    Icon,
    Button,
    InputSearch,
    prefixOutline,
    campusOutline,
    agencyOutline,
    userfieldOutline,
    templateoutline,
    positionOutline,
    educationlevelOutline,
    usertypeOutline,
    parameterOutline,
    BIconFilterOutline,
    FilterActive,
    UserOutline,
    Heading,
    // modal,
  },
  data() {
    return {
      typeSchema: typeSchema,
      data: {},
      linkTo: "/campus/add",
      scroll: null,
    };
  },

  emits: ["onInput", "selectOption", "onSearch", "onClean", "showFilter"],
  props: {
    breadcrumbname: { type: String, default: "" },
    morebreadcrumbname: { type: String, default: "" },
    headerNamebreadcrumb: { type: String, default: "" },
    headerIcon: { type: String },
    headerName: { type: String },
    DetailheaderName: { type: String },
    btnName: { type: String },
    disabled: { type: Boolean, default: false },
    helperHeader: { type: String },
    breadcrumb: { type: String },
    rightHeader: { type: String },
    searchHeader: { type: String },
    sortHeader: { type: String },
    addHeader: { type: String },
    underline: { type: String },
    hintHelperHeader: { type: String },
    campusListClass: { type: String },
    dataselection: {},
    ListCampusClass: { type: String },
    Searchname: { type: String },
    blnOpenform: { type: Boolean },
    showFilter: { type: Boolean },
    filterActive: { type: Object },
    icon: { type: String },
    morebreadcrumbpath: { type: Boolean, default: false },
    widthIcon: {
      type: Number,
      default: 32,
    },
    heightIcon: {
      type: Number,
      default: 32,
    },
    filter: { type: String, default: "" },
    heading: { type: Boolean, defualt: false },
    mode: { type: String, default: "auto" },
    tooltipMsg: { type: String, default: "101" },
    tooltipSearchMsg: { type: String },
    tooltipPlacement: { type: String, default: "tooltip-top" },
    blnShowSearchInput: { type: Boolean, default: true },
    classAutoSearch: { type: String },
  },

  methods: {
    onClear() {
      if (this.$refs.inputSearch.strSearch != null) {
        this.$refs.inputSearch.strSearch = null;
      }
    },
    clean() {
      this.$emit("onClean");
    },
    OpenForm(event, index) {
      // console.log(index)
      this.$emit("onInput", event);
      if (index != undefined) {
        this.$emit("clickindex", index);
      }
    },
    OpenFilter() {
      this.$emit("showFilter", !this.filterActive.blnOpenform);
    },

    selected(state) {
      // console.log("selected");

      this.$emit("selectOption", state);
    },
    search(event) {
      this.$emit("onSearch", event);
    },
    link() {
      this.$router.push("/campus/add");
    },
    scrollFunction() {
      if (this.blnOpenform == false) {
        if (
          document.body.scrollTop > 75 ||
          document.documentElement.scrollTop > 75
        ) {
          this.scroll = true;
        } else {
          this.scroll = false;
        }
      } else if (this.blnOpenform == true) {
        if (
          document.body.scrollTop > 50 ||
          document.documentElement.scrollTop > 50
        ) {
          this.scroll = !this.blnOpenform;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.customInput {
  min-width: 100% !important;
  max-width: 220px !important;
}

.openUnderline {
  border-bottom: solid 1px #e1e1e1;
  padding-bottom: 8px;
}

.closeUnderline {
  border-bottom: none;
  padding-bottom: 0;
}

.dropdown-header {
  font-size: 18px;

  &:focus {
    box-shadow: none !important;
  }

  option {
    font-size: 18px;
    border: none;
    padding: 16px auto;
  }
}

.onScroll {
  position: sticky;
  transition: 0.2s;
  padding: 8px 24px;
  background-color: #fff;
  top: 73px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.04), 0px 4px 10px rgba(0, 0, 0, 0.08);
  z-index: 10;
}

.unOnScroll {
  padding: 0 !important;
  background-color: none !important;
  box-shadow: none !important;
  position: relative !important;
}

.used {
  position: relative;

  .red-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $danger;
    position: absolute;
    top: 6px;
    right: 12px;
  }
}

.iconhead {
  fill: red !important;
}

.disabled {
  position: relative;

  .box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  svg {
    fill: $black-500 !important;
  }
}
.disabled_button {
  background-color: $black-400;
  position: relative;
}
.fix {
  width: 320px !important;
}

.tooltiper {
  position: relative;
  &:hover {
    &::before,
    &::after {
      opacity: 1;
      visibility: visible;
      transition-property: opacity visibility;
      transition-duration: 150ms;
      transition-delay: v-bind("tooltipDelay");
    }
  }
  &::before {
    z-index: 9999;
    position: absolute;
    // left: 50%;
    // top: -28px;
    // transform: translate(-50%, -50%);
    content: attr(data-tooltip);
    color: $white;
    background-color: $black;
    padding: 8px;
    border-radius: 4px;
    width: max-content;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    transition-property: opacity visibility;
    transition-duration: 150ms;
    white-space: pre;
    // transition-delay: v-bind("delay");
  }
  &::after {
    position: absolute;
    // left: 50%;
    // top: -14px;
    // transform: translate(-50%, -50%);
    z-index: 9999;
    content: "";
    background-color: $black;
    width: 9px;
    height: 9px;
    transform: rotate(-45deg);
    opacity: 0;
    visibility: hidden;
    transition-property: opacity visibility;
    transition-duration: 100ms;
    // transition-delay: v-bind("delay");
  }
}
.tooltiper-mobile {
  position: relative;
  &:hover {
    &::before,
    &::after {
      opacity: 1;
      visibility: visible;
      transition-property: opacity visibility;
      transition-duration: 150ms;
      transition-delay: v-bind("tooltipDelay");
    }
  }
  &::before {
    z-index: 9999;
    position: absolute;
    // left: 50%;
    // top: -28px;
    // transform: translate(-50%, -50%);
    content: attr(data-tooltip);
    color: $white;
    background-color: $black;
    padding: 8px;
    border-radius: 4px;
    width: max-content;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    font-size: 2.6vw !important;
    font-weight: 400;
    transition-property: opacity visibility;
    transition-duration: 150ms;
    white-space: pre;
    // transition-delay: v-bind("delay");
  }
  &::after {
    position: absolute;
    // left: 50%;
    // top: -14px;
    // transform: translate(-50%, -50%);
    z-index: 9999;
    content: "";
    background-color: $black;
    width: 9px;
    height: 9px;
    transform: rotate(-45deg);
    opacity: 0;
    visibility: hidden;
    transition-property: opacity visibility;
    transition-duration: 100ms;
    // transition-delay: v-bind("delay");
  }
}
.tooltip-top {
  &::before {
    left: 50%;
    bottom: 130%;
    transform: translateX(-50%);
  }
  &::after {
    left: 50%;
    bottom: calc(100% + 9px);
    transform: translateX(-50%);
    rotate: -45deg;
  }
}
</style>
